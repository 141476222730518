<template>
  <div>
    <b-card>
      <b-form>
        <b-row>
          <b-col lg="4" class="mb-1">
            <b-form-input
              v-model="filter.search"
              placeholder="Correo electrónico, Teléfono, Nombre y apellido"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" class="mb-1">
            <v-select
              class="mx-select"
              v-model="filter.school_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionSchools"
              :reduce="(item) => item.id"
              placeholder="Colegios"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" class="mb-1">
            <v-select
              v-model="filter.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionTypes"
              :reduce="(item) => item.id"
              placeholder="Tipo de cuenta"
              label="name"
              @input="filterabled"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="mb-1" v-if="filter.type == 'postpaid'">
            <v-select
              v-model="filter.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionStatus"
              :reduce="(item) => item.id"
              placeholder="Estado"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" class="mb-1" v-if="filter.type == 'postpaid'">
            <v-select
              v-model="filter.expiration"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionExpirations"
              :reduce="(item) => item.id"
              placeholder="Fecha de expiración"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" class="mb-1">
            <b-form-input
              v-model="filter.amount"
              type="text"
              placeholder="Monto"
              @input="filterabled"
            />
          </b-col>
          <b-col
            :lg="filter.type == 'postpaid' ? '12' : '8'"
            align-self="start"
            class="d-flex justify-content-end"
          >
            <b-button variant="primary" @click="restareFilter">
              Reiniciar
            </b-button>
            <b-button v-if="isAdmin || isCashier" variant="primary ml-2" @click="openCreateModal">
              Agregar 
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <CreateStudent :typeReadOnly="true" :showModal="showCreateModal" :fromParent="false" :typeUser="typeUser" @closeModal="closeModal" @refresh="refresh" />
  
      
      <div class="mx-auto p-1"></div>

      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
        class="table-responsive"
      >
        <b-table
          hover
          :items="items.data"
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No hay usuarios registrados"
        >
          <template #cell(name)="data">
            <b-link
              :to="{ name: 'profile-user', params: { id: data.item.id } }"
              class="font-weight-bold d-block"
            >
              <p class="text-nowrap">
                {{ data.item.name + " " + data.item.last_name }}
              </p>
            </b-link>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTimeHumane }}
          </template>

          <template #cell(wallet)="data">
            <div class="t-a-center">
              <b-badge
                v-if="data.item.wallet"
                pill
                :variant="
                  data.item.wallet.balance <= 0
                    ? 'light-danger'
                    : 'light-success'
                "
                class="d-block text-capitalize"
              >
                Cuenta {{ data.item.wallet.type }} Saldo
                {{
                  data.item.wallet.balance
                    | currency({ symbol: "$", precision: 2 })
                }}
              </b-badge>

              <vue-qr
                :text="appUrl + 'profile/' + data.item.id + '/user'"
                :size="50"
                :margin="0"
                :logoCornerRadius="10"
                :bindElement="true"
              ></vue-qr>

              <b-badge
                v-if="
                  data.item.wallet &&
                  data.item.wallet.type == 'Pospago' &&
                  data.item.wallet.renewed_days > 0
                "
                pill
                :variant="
                  data.item.wallet.type == 'Pospago'
                    ? 'light-warning'
                    : 'light-success'
                "
                class="d-block text-capitalize"
              >
                Vencimiento: {{ data.item.wallet.expiry }}
              </b-badge>

              <b-badge
                v-if="
                  data.item.wallet &&
                  data.item.wallet.type == 'Pospago' &&
                  data.item.wallet.max_limit
                "
                pill
                :variant="
                  data.item.wallet.type == 'Pospago'
                    ? 'light-success'
                    : 'light-success'
                "
                class="d-block text-capitalize"
              >
                Credito: ${{ data.item.wallet.max_limit }}
              </b-badge>
            </div>
          </template>

          <template #cell(roles)="data">
            <div v-if="data.item.roles.length > 0" class="text-nowrap">
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50"
                :class="`text-success`"
              />
              <span class="align-text-top text-capitalize">{{
                data.item.roles[0].label
              }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="openPasswordModal(data.item.id)">
                <feather-icon icon="KeyIcon" />
                <span class="align-middle ml-50">Cambiar Contraseña</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'user-edit',
                    params: { id: data.item.id, typeUser: data.item.typeUser },
                  })
                "
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteMetho(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="isCashier || isAdmin" @click="messageWhatsApp(data.item)">
                <feather-icon icon="TagIcon" />
                <span class="align-middle ml-50">Cobrar whatsApp</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
    <b-modal
      v-model="updatePasswordModal"
      centered
      :title="'Cambiar contraseña'"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="Form">
        <b-form class="p-2" @submit.prevent="updatePassword">
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-form-group>
              <label for="basic-password">Password</label>
              <b-input-group>
                <b-form-input
                  v-model="formData.password"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <div v-if="showPassword">
            {{ formData.password }}
          </div>
          <hr />
          <b-button :variant="'primary'" class="mr-2" @click="randomPass()">
            <span class="align-middle">Generar contraseña aleatoria</span>
          </b-button>
          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button :variant="'success'" class="mr-2" type="submit">
              <feather-icon :icon="'PlusIcon'" class="mr-50" />
              <span class="align-middle">Actualizar</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BForm,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BInputGroup,
} from "bootstrap-vue";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import VueQr from "vue-qr";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
localize("es", es);
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import  CreateStudent  from "./CreateStudent/CreateStudent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
    BForm,
    VueQr,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BInputGroup,
    CreateStudent,
  },
  data: () => ({
    optionTypes: [],
    showPassword: false,
    isShowModalWhastApp: false,
    showCreateModal: false,
    formData: {
      password: "",
    },
    filter: {
      search: null,
      school_id: null,
      type: null,
      status: null,
      expiration: null,
      amount: null,
    },
    optionExpirations: [],
    currentPage: 1,
    required,
    page: 1,
    loading: false,
    items: {},
    typeUser: null,
    searchTimeout: null,
    updatePasswordModal: false,
    optionSchools: [],
    optionStatus: [
      { value: true, label: "Activo" },
      { value: false, label: "Inactivo" },
    ],
  }),
  mixins: [togglePasswordVisibility],
  filters: {
    formatDateTimeHumane,
  },
  computed: {
    appUrl() {
      return process.env.VUE_APP_APP_URL;
    },
    usersList() {
      return this.$store.state.user.users;
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    routeName() {
      return {
        "users-students": "student",
        "users-representatives": "representative",
        "users-teachers": "teacher",
        "users-admins": "admin",
        "users-cashiers": "cashier",
      };
    },
    fields() {
      if (this.isCashier) {
        return [
          { key: "id_card", label: "Cedula", sortable: true },
          { key: "name", label: "Nombre y Apellido", sortable: true },
          { key: "email", label: "Correo", sortable: true },
          { key: "phone", label: "Teléfono", sortable: true },
          { key: "wallet", label: "Billetera", sortable: true },
          { key: "roles", label: "Rol", sortable: true },
          { key: "school.name", label: "Colegio", sortable: true },
          { key: "created_at", label: "Creado", sortable: true },
          { key: "actions", label: "Acciones" },
        ];
      }
      if (this.typeUser == "representative" || this.typeUser == "admin") {
        let dataOther = [
          { key: "id_card", label: "Cedula", sortable: true },
          { key: "name", label: "Nombre y Apellido", sortable: true },
          { key: "email", label: "Correo", sortable: true },
          { key: "phone", label: "Teléfono", sortable: true },
          { key: "wallet", label: "Billetera", sortable: true },
          { key: "roles", label: "Rol", sortable: true },
          { key: "school.name", label: "Colegio", sortable: true },
          { key: "created_at", label: "Creado", sortable: true },
        ];

        if (this.isAdmin) {
          dataOther.push({ key: "actions", label: "Acciones" });
        }

        return dataOther;
      } else if (this.typeUser == "cashier") {
        return [
          { key: "name", label: "Nombre y Apellido", sortable: true },
          { key: "email", label: "Correo", sortable: true },
          { key: "email", label: "Correo", sortable: true },
          { key: "phone", label: "Teléfono", sortable: true },
          { key: "roles", label: "Rol", sortable: true },
          { key: "school.name", label: "Colegio", sortable: true },
          { key: "created_at", label: "Creado", sortable: true },
          { key: "actions", label: "Acciones" },
        ];
      } else {
        let mainData = [
          { key: "name", label: "Nombre y Apellido", sortable: true },
          { key: "email", label: "Correo", sortable: true },
          { key: "phone", label: "Teléfono", sortable: true },
          { key: "id_card", label: "Cedula", sortable: true },
          { key: "wallet", label: "Billetera", sortable: true },
          { key: "degree", sortable: true, label: "Grado" },
          { key: "section", sortable: true, label: "Sección" },
          { key: "classroom", sortable: true, label: "Aula" },
          { key: "roles", label: "Rol" },
          { key: "school.name", label: "Colegio", sortable: true },
          { key: "created_at", label: "Creado", sortable: true },
        ];

        if (this.isAdmin) {
          mainData.push({ key: "actions", label: "Acciones" });
        }

        return mainData;
      }
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.typeUser = this.routeName[route.name];
        this.getItems();
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.resources();
  },
  methods: {
    async filterabled() {
      this.getItems(this.currentPage);
    },
    async resources() {
      await this.$store
        .dispatch("app/resources", { users: true })
        .then((res) => {
          this.optionSchools = res.schools;
          this.optionStatus = res.status;
          this.optionTypes = res.types;
          this.optionExpirations = res.expiration;
        });
    },
    restareFilter() {
      this.filter = {
        search: null,
        school_id: null,
        type: null,
        status: null,
        amount: null,
      };
      this.getItems(this.currentPage);
    },
    openCreateModal() {
      this.showCreateModal = false;
      setTimeout(() => {
        this.showCreateModal = true;

      }, 100);
    },
    closeModal() {
      this.showCreateModal = false;
    },
    refresh(){
      this.getItems();
      this.closeModal();
    },
    async getItems(page = 1) {
      this.currentPage = page;
      try {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
          this.loading = true;
          this.items = await this.$store.dispatch("user/fetchUsers", {
            ...this.filter,
            ...this.$route.query,
            page: this.currentPage,
            typeUser: this.typeUser,
          });
          this.loading = false;
        }, 500); // 500ms de retraso
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    avatarText(fullName) {
      return avatarText(fullName);
    },
    randomPass() {
      this.showPassword = true;
      var length = 15,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.formData.password = retVal;
    },
    openPasswordModal(userId) {
      this.updatePasswordModal = !this.updatePasswordModal;
      this.formData.userId = userId;
    },
    async updatePassword() {
      await this.$store
        .dispatch("user/updatePassword", this.formData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Contraseña modificada exitosamente",
            },
          });
          this.updatePasswordModal = false;
        });
    },
    async deleteMetho({ id, name }) {
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          `Estas seguro de borrar el usario ${name}?`,
          {
            title: "Por favor confirmar",
            size: "sm",
            okVariant: "primary",
            okTitle: "Si",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (res) {
          await store.dispatch("user/delete", id);
          this.getItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Usuario eliminado correctamente",
            },
          });
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo eliminar el usario, puede que este siendo usado.",
          },
        });
      }
    },
    messageWhatsApp(user) {
      if (user.phone.length < 10) {
        this.$swal({
          title: `EL usuario no tiene un numero de telefono valido`,
          text: `Número: ${user?.phone}`,
          icon: "error",
        });
        return;
      }

      if (user.wallet == null || user.wallet?.balance >= 0) {
        this.$swal({
          title: `El usuario ${user?.name} ${user?.last_name} no tiene deuda`,
          icon: "success",
        });
        return;
      }

      this.$swal({
        title: `Enviar un mensaje a ${user?.name} ${user?.last_name}?`,
        text: `Número: ${user?.phone}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Enviar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch("user/sendWhatsAppMessage", user.id);
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Mensaje enviado correctamente",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.d-contens {
  display: contents;
}

.t-a-center {
  text-align: center;
}

.table-responsive {
  min-height: 250px;
}
</style>
