<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    no-caret
  >
    <template #button-content>
      <feather-icon
        :badge="resNotificactions.length"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <template :hiden="hiddenDow" #default>
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notificaciones de Ordenes</h4>
        </div>
      </li>

      <!-- Cart Items -->
      <vuescroll
        v-if="resNotificactions.length"
        :ops="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        style="height: calc(100vh - 300px)"
        tagname="li"
      >
        <div v-for="order in resNotificactions" :key="order.orderId"
          @click="openOrder(order)"
          class="cursor-pointer"
        >
          <b-overlay
            :show="isLoading(order.orderId)"
            variant="white"
            spinner-variant="danger"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <div class="d-flex align-items-center order-info mt-1">
              <!-- Botón para eliminar la orden -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon ml-1"
                pill
                @click="removeGroup(order.orderId)"
              >
                <feather-icon icon="XIcon" />
              </b-button>

              <!-- Información del pedido -->
              <h6 class="text-dark font-weight-bold ml-0 mb-0">
                {{ order.fullname }}
                <b-badge v-if="Array.isArray(order.items)" variant="danger" class="ml-2">Venta</b-badge> 
                <b-badge v-else variant="success" class="ml-2">Recarga</b-badge>

                <b-badge v-if="order.transaction && order.transaction.status" :variant="statusGetClass(order.transaction.status)" class="ml-2">{{order.transaction.status}}</b-badge>
              </h6>
            </div>

            <p class="text-muted small ml-4">{{ order.created_at }}</p>

            <div v-if="Array.isArray(order.items)">
              <div v-for="item in order.items" :key="item.name">
                <b-media class="mt-0">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <h6>({{ item.qty }}) - {{ item.name }}</h6>
                    <div class="d-flex flex-column align-items-end">
                      <h6 class="cart-item-price mb-0">
                        {{ item.total.usd | currency({ symbol: "$", precision: 2 }) }}
                      </h6>
                      <hr class="border-line my-0 w-100">
                      <h6 class="mt-0">
                        {{ item.total.bs | currency({ symbol: "Bs", precision: 2 }) }}
                      </h6>
                    </div>
                  </div>
                </b-media>
              </div>
            </div>

            <div v-else>
              <b-media class="mt-0">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <h6>{{ order.items }}</h6>
                </div>
              </b-media>
            </div>
          </b-overlay>
        </div>
      </vuescroll>

      <p v-if="!resNotificactions.length" class="m-0 p-1 text-center">
        No hay nuevas ordenes
      </p>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BImg,
  BFormSpinbutton,
  BButton,
  BOverlay,
  BDropdownItem,
} from "bootstrap-vue";
import vuescroll from "vuescroll";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    vuescroll,
    BButton,
    BOverlay,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.fetchItems();	
  },
  beforeDestroy() {
    clearInterval(this.fetchInterval);
  },
  data() {
    return {
      fetchInterval: null,
      resNotificactions: [],
      items: [],
      loadingOrders: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
    };
  },
  methods: {
    openOrder(order){
      if(order.transaction){
        this.$router.push({ name: 'transactions', query: { transactionId: order?.transaction?.id } })
      }
    },
    statusGetClass(status){
      switch (status) {
        case 'Pendiente':
          return 'warning'
        case 'Aprobado':
          return 'success'
        case 'Rechazado':
          return 'danger'
        default:
          return 'secondary'
      }
    },
    async fetchItems() {
      this.loading = true;
      await this.$store
        .dispatch("notifications/fetchNotifacationItems")
        .then((res) => {
          if (this.resNotificactions.length < res.length) {
            this.message(
              "Nueva orden",
              "checkCircleIcon",
              "success",
              "Tienes notificaciones pendientes"
            );
          }
          this.resNotificactions = res;
          this.loading = false;
        })
        .catch((res) => {
          this.message(
            "Orden de compra",
            "AlertCircleIcon",
            "danger",
            res.error || "Falló en la carga"
          );
          this.loading = false;
        });
    },
    async removeGroup(orderId) {
       this.loadingOrders.push(orderId);

      try {
        await this.$store.dispatch("notifications/fetchNotifacationItemsClose", orderId);
        this.resNotificactions = this.resNotificactions.filter(order => order.orderId !== orderId);
        this.message(
          "Orden cerrada",
          "checkCircleIcon",
          "danger",
          "Orden cerrada con exito"
        );
      } catch (res) {
        this.message(
          "Orden de compra",
          "AlertCircleIcon",
          "danger",
          res.error || "Falló al cerrar la notificación"
        );
      } finally {
        this.loadingOrders = this.loadingOrders.filter(id => id !== orderId);
       
      }
    },
    isLoading(orderId) {
      return this.loadingOrders.includes(orderId);
    },
    message(message, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: message,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
