<template>
  <div>
    <b-nav-item >
      <div style=" width: 30px;height: 30px;" @click="cameraOpen = !cameraOpen">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0L11.34 .03L15.15 3.84L16.5 2.5C19.75 4.07 22.09 7.24 22.45 11H23.95C23.44 4.84 18.29 0 12 0M12 4C10.07 4 8.5 5.57 8.5 7.5C8.5 9.43 10.07 11 12 11C13.93 11 15.5 9.43 15.5 7.5C15.5 5.57 13.93 4 12 4M.05 13C.56 19.16 5.71 24 12 24L12.66 23.97L8.85 20.16L7.5 21.5C4.25 19.94 1.91 16.76 1.55 13H.05M12 13C8.13 13 5 14.57 5 16.5V18H19V16.5C19 14.57 15.87 13 12 13Z" /></svg>
      </div>
    </b-nav-item>
    <b-modal v-model="cameraOpen" centered :title="'Buscar Usuario'" hide-footer no-close-on-backdrop>
        <qrcode-stream @decode="onDecode"></qrcode-stream>
    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavItem } from 'bootstrap-vue'
import { VBModal } from 'bootstrap-vue'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    BNavItem,
    VBModal,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },
  data(){
    return {
      cameraOpen: false,
    }
  },
  methods: {
async onDecode(walletId) {
  try {
    const res = await this.$store.dispatch('user/searchUserInWallet', walletId);
    if (res) {
      this.cameraOpen = false;

      this.$router.push({ name: 'profile-user', params: { id: res } }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Error al navegar:', err);
        }
      });
    } else {
      console.error('No se pudo obtener el ID del usuario');
    }
  } catch (error) {
    
  }
}

  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>
