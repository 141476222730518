var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("ButtonAction",function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Nuevo ")],1)]}),_c('validation-observer',{ref:"categoryForm"},[_c('b-modal',{attrs:{"id":"modal-categories","ok-title":_vm.modeEdit ? 'Actualizar' : 'Guardar',"cancel-title":"Cancelar","centered":"","title":_vm.modeEdit ? 'Editar categoría' : 'Nueva categoría'},on:{"ok":_vm.saveMetho},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","responsive":"","variant":"transparent","opacity":"0.33","blur":"2px"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveMetho.apply(null, arguments)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre:")]),_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"Descripcion"}},[_vm._v("Descripción")]),_c('validation-provider',{attrs:{"name":"Descripción","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('validation-provider',{attrs:{"name":"Descripción","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Descripcion","placeholder":"Descripción","rows":"3","max-rows":"6"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Colegio"}},[_c('validation-provider',{attrs:{"name":"Colegio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.schoolOptions,"label":"name","reduce":function (item) { return item.id; }},model:{value:(_vm.formData.school_id),callback:function ($$v) {_vm.$set(_vm.formData, "school_id", $$v)},expression:"formData.school_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.image,"variant":"light-success","size":"250px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.uploadImage}}),_c('feather-icon',{staticClass:"d-inline mr-sm-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Subir")])],1)],1)])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }