<template>
  <b-nav-item-dropdown
  v-if="$route.name !== 'apps-e-commerce-shop-public'"
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    no-caret>
    <template #button-content>
      <feather-icon
        :badge="$store.state['app-ecommerce'].cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"/>
    </template>

    <template :hiden="hiddenDow" #default>
      <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Mi carrito
        </h4>
        <b-badge pill variant="light-primary">
          {{ $store.state['app-ecommerce'].cartItemsCount }} Productos
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vuescroll
      v-if="cartProducts.length"
      :ops="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="height: calc(100vh - 300px)"
      tagname="li"
      >
      <b-media v-for="item in cartProducts" :key="item.name">
        <template #aside>
          <b-img :src="item.image" :alt="item.name" rounded width="62px"/>
        </template>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon"
          pill
        >
          <feather-icon
            icon="XIcon"
            @click.stop="removeItemFromCart(item.id)"
          />
        </b-button>        
        <h6 class="cart-item-title">
          {{ item.name }}
        </h6>
        <h5 class="cart-item-price">
          {{ item.price | currency({ symbol: '$', precision: 2 }) }}
        </h5>
        
      </b-media>
    </vuescroll>

    <!-- Cart Footer -->
    <li v-if="cartProducts.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ totalAmount | currency({ symbol: '$', precision: 2 }) }}
        </h6>
      </div>
      <b-dropdown-item>
           <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block 
              @click="paymentShow"
            >
            Pagar
          </b-button>
      </b-dropdown-item>
      
    </li>
    <p v-if="!cartProducts.length" class="m-0 p-1 text-center">
     Tu carrito esta vacío
    </p>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton, BDropdownItem
} from 'bootstrap-vue'
import vuescroll from 'vuescroll'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    vuescroll,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
             background: '#01a99a',
          },
          bar: {
            background: '#ee3926'
          },
      },
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.cartProducts.forEach(i => { total += i.price * i.qty })
      return total
    },
    cartProducts(){
      return this.$store.state['app-ecommerce'].cartItems.slice().reverse()
    },
  },
  methods: {
    fetchItems() {
      return this.cartProducts
    },
    removeItemFromCart(productId) {
      this.$store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', productId)
      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.$store.state['app-ecommerce'].cartItemsCount - 1) 
    },
    async paymentShow() {
      // await this.$store.dispatch('app-ecommerce/addCartProducts', {
      //   cartItems: this.cartProducts.map(item => {
      //     return {
      //       productId: item.id,
      //     }
      //   })
      // })
      // .then(() => {
      //   this.$router.push({ name: 'apps-e-commerce-checkout' })
      // })
      this.$router.push({ name: 'apps-e-commerce-checkout' })

    },
    hiddenDow() {
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
