<template>
  <div>
    <b-card class="mb-0">
 <b-form>
        <b-row>
          <b-col lg="4" class="mb-1" v-if="isCashier || isAdmin">
            <b-form-input
              v-model="filter.search"
              placeholder="Correo electrónico o Teléfono"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" v-if="isCashier || isAdmin">
            <v-select
              class="mx-select"
              v-model="filter.school_id"
              :options="optionSchools"
              :reduce="(item) => item.id"
              placeholder="Colegios"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col :lg="isCashier || isAdmin ? 4 : 12" class="mb-1">
            <v-select
              v-model="filter.payment_type_id"
              :options="optionsPaymentTypes"
              :reduce="(item) => item.id"
              placeholder="Tipo de operación"
              label="name"
              @input="filterabled"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col :lg="isCashier || isAdmin ? 4 : 6" class="mb-1">
            <v-select
              v-model="filter.payment_method_id"
              :options="optionsPaymentMethods"
              :reduce="(item) => item.code"
              multiple
              placeholder="Método de pago"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col :lg="isCashier || isAdmin ? 4 : 6" class="mb-1">
            <v-select
              v-model="filter.status"
              :options="optionStatus"
              :reduce="(item) => item.id"
              placeholder="Estado"
              label="name"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="4" v-if="isCashier || isAdmin">
            <b-form-input
              v-model="filter.amount"
              type="number"
              placeholder="Monto"
              @input="filterabled"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="mb-1">
            <date-picker
              v-model="filter.date"
              range
              type="date"
              valueType="format"
              format="DD/MM/YYYY"
              :default-value="new Date()"
              :disabled-date="disabledBeforeToday"
              placeholder="Fecha"
              @input="filterabled"
            />
          </b-col>
          <b-col lg="8" class="d-flex justify-content-end items-end">
            <b-button variant="primary" @click="restareFilter" class="ml-1">
              Reiniciar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-overlay
        :show="loading"
        rounded
        responsive
        class="table-responsive"
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <b-table
          :items="items.data"
          :fields="fields"
          primary-key="id"
          show-empty
          empty-text="No hay transacciónes registradas"
          class="mt-3"
        >
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${statusGetClass(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(from)="data">
            <span v-if="data.item.from == '' || data.item.from == 'APP'"> APP </span>
            <span v-else-if="data.item.from == 'ATM'"> ATM </span>
          </template>

          <template #cell(screenshot)="data">
            <a
              v-if="data.item.screenshot"
              :href="apiUrlScreenshot + data.item.screenshot"
              target="_blank"
            >
              <img
                :src="apiUrlScreenshot + data.item.screenshot"
                width="100"
                height="100"
                @error="handleImageError($event)"
                alt="screenshot"
                style="object-fit: contain"
              />
            </a>
          </template>

          <template #cell(invoice)="data">
            <b-link
              v-if="data.item.invoice"
              :to="{
                name: 'apps-invoice-preview',
                params: { id: data.item.invoice.id },
              }"
              class="font-weight-bold d-block"
            >
              <p class="text-nowrap">Factura #{{ data.item.invoice.id }}</p>
            </b-link>
            <p v-else class="text-nowrap">Recarga</p>
          </template>

          <template #cell(payment_type)="data">
            <ul>
              <li v-for="item in data.item.payments" :key="item.id">
                {{ item.paymentMethod }}: ${{ item.amount }}
              </li>
            </ul>
          </template>

          <template #cell(user)="data">
            <b-link
              v-if="!data.item.user.deleted_at"
              :to="{ name: 'profile-user', params: { id: data.item.user.id } }"
              class="font-weight-bold d-block"
            >
              <p class="text-nowrap">
                {{ data.item.user.name }} {{ data.item.user.last_name }}
              </p>
            </b-link>
            <p v-else-if="data.item.user.deleted_at" class="text-nowrap">
              {{ data.item.user.name }}
            </p>
          </template>

          <template #cell(previous_balance)="data">
            <p class="text-nowrap" v-if="data.item.previous_balance">
              {{ data.item.previous_balance | currency({ symbol: "$", precision: 2 }) }}
            </p>
            <p v-else class="text-center">-</p>
          </template>

          <template #cell(currentBalance)="data">
            <p class="text-nowrap" v-if="data.item.previous_balance">
              {{ resultBalance(data) | currency({ symbol: "$", precision: 2 }) }}
            </p>
            <p v-else class="text-center">-</p>
          </template>

          <template #cell(amount)="data">
            <p
              class="text-nowrap"
              :class="data.item.type == 'Venta' ? 'text-primary' : 'text-success'"
            >
              {{ data.item.type == "Venta" ? "-" : "" }}

              {{ data.item.amount | currency({ symbol: "$", precision: 2 }) }}
            </p>
          </template>

          <template #cell(created_at)="data">
            <p class="text-nowrap">
              {{ data.item.created_at | formatDateTimeHumane }}
            </p>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div>
              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="openDetails(data.item)">
                  <feather-icon icon="EyeIcon" />
                  <span class="align-middle ml-50">Detalles</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="showButtonForAction(data.item)"
                  @click="approve(data.item.id, data.item.user_id)"
                >
                  <feather-icon icon="ArrowUpLeftIcon" />
                  <span class="align-middle ml-50">Aprobar</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="showButtonForAction(data.item)"
                  @click="openModalReject(data.item)"
                >
                  <feather-icon icon="ArrowDownRightIcon" />
                  <span class="align-middle ml-50">Rechazar</span>
                </b-dropdown-item>

                <!-- Cancelar: sirve para cancelar despues de aprobada -->
                <b-dropdown-item
                  v-if="data.item.status == 'Aprobado' && false"
                  @click="openModalCancel(data.item)"
                >
                  <feather-icon icon="ArrowDownRightIcon" />
                  <span class="align-middle ml-50">Cancelar</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
    <b-modal
      v-model="showModal"
      hide-footer
      no-close-on-backdrop
      centered
      v-if="isCashier || isAdmin"
      :title="'Rechazar Transacción'"
    >
      <validation-observer ref="formReject">
        <b-form @submit.prevent="reject">
          <b-form-group label="Motivo de Rechazo*">
            <validation-provider #default="{ errors }" name="opciones" rules="required">
              <v-select
                class="mx-select"
                v-model="formData.option"
                :options="rejectOptions"
                :reduce="(item) => item.id"
                @input="validRejectWalletTransaction($event)"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Monto a debitar *" v-if="formData.option == 1">
            <validation-provider #default="{ errors }" name="monto" rules="required">
              <b-form-input
                disabled
                v-model="formData.debit"
                type="number"
                step="0.01"
                placeholder="Monto"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Monto a creditar *" v-if="formData.option == 1">
            <validation-provider #default="{ errors }" name="monto" rules="required">
              <b-form-input
                v-model="formData.credit"
                type="number"
                step="0.01"
                placeholder="Monto"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Comentario *">
            <validation-provider #default="{ errors }" name="comentario" rules="required">
              <b-form-textarea v-model="formData.comment" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <footer class="modal-footer p-0">
            <b-button variant="secondary" @click="close"> Cancelar </b-button>
            <b-button variant="primary" type="submit"> Notificar </b-button>
          </footer>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      v-if="isCashier || isAdmin"
      v-model="openModalDetails"
      hide-footer
      centered
      size="xl"
      :title="'Detalles de Transacción'"
    >
      <div>
        <table role="table" aria-busy="false" aria-colcount="3" class="table b-table">
          <thead role="rowgroup">
            <tr role="row">
              <th scope="col">Cliente</th>
              <th scope="col">Detalles de Pago</th>
              <th scope="col">Responsable</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr role="row">
              <td role="cell">
                <b-link
                  :to="{
                    name: 'profile-user',
                    params: { id: details.user.id },
                  }"
                  class="font-weight-bold"
                >
                  <p class="text-nowrap">
                    {{ `${details.user.name} ${details.user.last_name}` }}
                  </p>
                </b-link>
              </td>
              <td role="cell">
                <div
                  v-for="item in details.payments"
                  :key="item.id"
                  class="payment-details"
                >
                  <ul>
                    <li>Método: {{ item.paymentMethod }}</li>
                    <li>Monto: ${{ item.amount }}</li>
                    <li v-if="item.refId">Referencia: {{ item.refId }}</li>
                    <li v-if="item.emitterPhone">
                      Telefono emisor: {{ item.emitterPhone }}
                    </li>
                    <li v-if="item.screenshot">
                      <img
                        :src="apiUrlScreenshot + item.screenshot"
                        width="100"
                        height="100"
                        @click="showImg"
                        @error="handleImageError($event)"
                        alt="screenshot"
                        class="img-preview"
                      />
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="[apiUrlScreenshot + item.screenshot]"
                        :index="index"
                        @hide="handleHide"
                      />
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <ul v-if="details.responsible">
                  <li>
                    {{ `${details.responsible.name} ${details.responsible.last_name}` }}
                  </li>
                  <li v-if="details.responsible.phone">
                    TLF: {{ details.responsible.phone }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  VBModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
localize("es", es);
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
    VBModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    DatePicker,
    VueEasyLightbox,
  },
  props: {
    wallets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rejectOptions: [],
      visible: false,
      index: 0, // default: 0
      details: {
        user: {},
        payments: [],
      },
      openModalDetails: false,
      optionSchools: [],
      optionsPaymentTypes: [],
      optionsPaymentMethods: [],
      optionStatus: [],
      filterable: false,
      searchTimeout: null,
      filter: {
        email: null,
        date: [moment().subtract(1, 'month').format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
        school_id: null,
        payment_type_id: null,
        payment_method_id: null,
        status: null,
      },
      required,
      email,
      loading: false,
      currentPage: 1,
      items: {},
      showModal: false,
      formData: {
        transactionId: null,
        option: null,
        comment: null,
        debit: null,
        credit: null,
        invoice_id: null,
      },
      imgError: process.env.VUE_APP_AWS_BUCKET + "/icons/PNG/Imagen_no_disponible.png",
    };
  },
  directives: {
    "b-modal": VBModal,
  },
  filters: {
    formatDateTimeHumane,
  },
  computed: {
    apiUrlScreenshot() {
      return process.env.VUE_APP_AWS_BUCKET + "/screenshots/";
    },
    transactionsList() {
      return this.$store.state.transactions.transactions;
    },
    transactionApprove() {
      return this.$store.state.user.permissions.find(
        (permission) => permission.name == "transaction.approve"
      );
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    fields() {
      let data = [
        { key: "invoice", label: "#", sortable: true },
        { key: "user", label: "Usuario", sortable: true },
        { key: "school.name", label: "Colegio", sortable: true },
        { key: "type", label: "Tipo", sortable: true },
        { key: "payment_type", label: "Metodo de Pago", sortable: true },
        { key: "previous_balance", label: "Saldo Anterior", sortable: true },
        { key: "amount", label: "Monto", sortable: true },
        { key: "currentBalance", label: "Saldo Actual", sortable: true },
        { key: "status", label: "Estatus", sortable: true },
        { key: "from", label: "Conexión", sortable: true },
        { key: "created_at", label: "Creado", sortable: true },
      ];

      if (this.transactionApprove || this.isAdmin) {
        data.push({ key: "actions", label: "Acciones" });
      }

      return data;
    },
    query() {
      return this.$route.query;
    },
  },
  mounted() {
    this.components();
    this.getItems();
    window.Echo.private("notifications-invoices-channel").listen(
      "NotificationEventInvoice",
      () => {
        this.getItems();
      }
    );
  },
  watch: {
    query() {
      this.getItems();
    },
  },
  methods: {
    handleImageError(event) {
      event.target.src = this.imgError;
    },
    validRejectWalletTransaction(option) {
      console.log(option, this.formData.payment_type_id);

      if (option == 1 && this.formData.payment_type_id !== 5) {
        this.formData.option = null;
        this.message(
          "infoIcon",
          "info",
          "Este caso solo aplica para transacciones de Billetera"
        );
      }

      if (option == 3 && this.formData.payment_type_id !== 2) {
        this.formData.option = null;
        this.message(
          "infoIcon",
          "info",
          "Este caso solo aplica para transacciones de Punto de Venta"
        );
      }

      if (option == 5 && this.formData.payment_type_id == 1) {
        this.formData.option = null;
        this.message(
          "infoIcon",
          "info",
          "Este caso solo aplica para transacciones de Transferencia y Pago movil"
        );
      }

      if (option == 4 && this.formData.payment_type_id == 2) {
        this.formData.option = null;
        this.message("infoIcon", "info", "Este caso solo aplica para pago en efectivo");
      } else if (option == 4 && this.formData.payment_type_id == 4) {
        this.formData.option = null;
        this.message("infoIcon", "info", "Este caso solo aplica para pago en efectivo");
      } else if (option == 4 && this.formData.payment_type_id == 5) {
        this.formData.option = null;
        this.message("infoIcon", "info", "Este caso solo aplica para pago en efectivo");
      } else if (option == 4 && this.formData.payment_type_id == 3) {
        this.formData.option = null;
        this.message("infoIcon", "info", "Este caso solo aplica para pago en efectivo");
      }

      if (option == 5 && this.formData.payment_type_id == 2) {
        this.formData.option = null;
        this.message(
          "infoIcon",
          "info",
          "Este caso solo aplica para de Transferencia y Pago movil"
        );
      } else if (option == 5 && this.formData.payment_type_id == 5) {
        this.formData.option = null;
        this.message(
          "infoIcon",
          "info",
          "Este caso solo aplica para de Transferencia y Pago movil"
        );
      }
    },
    showImg(index) {
      this.index = 1;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    restareFilter() {
      try {
        this.loading = true;
        this.filter = {
          email: null,
          date:[moment().subtract(1, 'month').format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
          school_id: null,
          payment_type_id: null,
          payment_method_id: null,
          status: null,
        };
        let query = Object.assign({}, this.$route.query);
        delete query.userId;
        this.$router.replace({ query });
        this.getItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    resultBalance(data) {
      const type = data.item.type;

      let amount =
        data.item.payments?.reduce((acc, payment) => {
          if (payment.paymentMethod == "WALLET" || payment.paymentMethod == "CREDIT") {
            return acc + parseFloat(payment.amount);
          }
          return acc;
        }, 0) || 0;

      amount = type == "Venta" ? amount : data.item.amount;

      const previous_balance = data.item.previous_balance;

      let total;
      if (!amount || !previous_balance) {
        return;
      }

      if (type == "Recarga") {
        total = parseFloat(previous_balance) + parseFloat(amount);
        return total.toFixed(2);
      } else if (type == "Venta") {
        total = parseFloat(previous_balance) - parseFloat(amount);
        return total.toFixed(2);
      }
    },

    openDetails(dataItem) {
      this.openModalDetails = true;
      this.details = dataItem;
    },
    disabledBeforeToday(date) {
      const today = new Date();

      return date >= new Date(today.getTime());
    },
    async getItems(page = 1) {
      this.currentPage = page;

      if (
        !this.filter.date ||
        this.filter.date.filter((item) => item == null).length > 0
      ) {
        delete this.filter.date;
      }
      try {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(async () => {
          this.loading = true;
          this.items = await this.$store.dispatch("transactions/index", {
            ...this.filter,
            ...this.$route.query,
            page: this.currentPage,
          });
          this.loading = false;
        }, 500); // 500ms de retraso
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    getPaymentMethod(payments) {
      const TRANSFERENCIA = payments.find(
        (payment) => payment.paymentMethod == "TRANSFERENCIA"
      );

      const PAGO_MOVIL = payments.find(
        (payment) => payment.paymentMethod == "PAGO_MOVIL"
      );

      if (TRANSFERENCIA) {
        return TRANSFERENCIA.refId;
      } else if (PAGO_MOVIL) {
        return PAGO_MOVIL.refId;
      }
    },
    isPagoMovilOrTranfer(details) {
      const TRANSFERENCIA = details.payments.find(
        (payment) => payment.paymentMethod == "TRANSFERENCIA"
      );

      const PAGO_MOVIL = details.payments.find(
        (payment) => payment.paymentMethod == "PAGO_MOVIL"
      );

      if (TRANSFERENCIA || PAGO_MOVIL) {
        return true;
      } else {
        return false;
      }
    },
    showButtonForAction(item) {
      if (item.payments !== null) {
        const TRANSFERENCIA = item.payments.find(
          (payment) => payment.paymentMethod == "TRANSFERENCIA"
        );

        const PAGO_MOVIL = item.payments.find(
          (payment) => payment.paymentMethod == "PAGO_MOVIL"
        );

        if (item.status == "Aprobado" || item.status == "Rechazado") {
          return false;
        }

        const isSale = item.type == "Venta";

        if (this.isAdmin) {
          if (TRANSFERENCIA || PAGO_MOVIL || isSale) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    close() {
      this.showModal = false;
      this.formData = {};
    },
    openModalReject(item) {
      this.showModal = true;
      this.formData.debit = item.amount;
      this.formData.payment_type_id = item.payment_type_id;
      if (item.invoice) {
        this.formData.invoice_id = item.invoice.id;
      }
      this.formData.transactionId = item.id;
    },
    openModalCancel(item) {
      // alert toast de confirmacion
      this.$swal({
        title: 'Confirmación',
        text: '¿Está seguro de cancelar la transacción?',
        icon: 'warning',
        dangerMode: true,
        buttons: {
          cancel: 'Cancelar',
          confirm: 'Confirmar',
        },
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("transactions/cancel", {
              transactionId: item.id,
              data: item,
            })
            .then(() => {
              this.getItems(this.currentPage);
              this.close();
              this.message("checkCircleIcon", "success", "Transacción cancelada");
            });
        }
      });
      
    },
    statusGetClass(status) {
      switch (status) {
        case "Pendiente":
          return "warning";
        case "Aprobado":
          return "success";
        case "Rechazado":
          return "danger";
        case "Cancelado":
          return "danger";
        default:
          return "secondary";
      }
    },
    fromGetClass(from) {
      switch (from) {
        case "APP":
          return "secondary";
        case "ATM":
          return "success";
        default:
          return "";
      }
    },
    async approve(transactionId, userId) {
      await this.$store.dispatch("transactions/approve", {
        id: transactionId,
        params: { userId: userId },
      });
      this.getItems(this.currentPage);
    },
    async components() {
      await this.$store.dispatch("app/resources", { transactions: true }).then((res) => {
        this.optionSchools = res.schools;
        this.optionsPaymentTypes = res.payment_types;
        this.optionsPaymentMethods = res.payment_methods;
        this.optionStatus = res.status;
        this.rejectOptions = res.rejectOptions;
      });
    },
    async reject() {
      if (!(await this.$refs.formReject.validate())) {
        return;
      }
      let data = {
        option: this.formData.option,
        comment: this.formData.comment,
        invoice_id: this.formData.invoice_id,
        payment_type_id: this.formData.payment_type_id,
      };

      if (this.formData.option == 1) {
        data.debit = parseFloat(this.formData.debit);
        data.credit = parseFloat(this.formData.credit);
      }

      await this.$store
        .dispatch("transactions/reject", {
          transactionId: this.formData.transactionId,
          data: data,
        })
        .then(() => {
          this.getItems(this.currentPage);
          this.close();
          this.message("checkCircleIcon", "success", "Transacción rechazada");
        });
    },
    message(icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Respuesta",
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
    async filterabled() {
      this.getItems(this.currentPage);
    },
    closeFilter() {
      this.showModalFilter = false;
      this.filterable = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mx-datepicker {
  width: 100%;
}

.mx-select {
  width: 100%;
}
.cursor-ointer {
  cursor: pointer;
}
.img-preview {
  object-fit: contain;
  cursor: pointer;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
li {
  margin: 0;
  padding: 0;
}
.payment-details {
  margin-bottom: 1em;
}
</style>
