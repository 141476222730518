<template>
  <div>
    <b-card>
      <b-row v-if="isAdmin">
        <b-col class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            @click="$router.push({ name: 'notification-create' })"
            class="d-flex align-items-center"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Nuevo</span>
          </b-button>
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        class="table-responsive"
        blur="2px"
      >
        <b-table
          hover
          :items="items.data"
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No hay notificaciones registradas"
        >
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(button)="data">
            <b-button
              variant="primary"
              @click="notificationPreview(data.item)"
            >
              Vista Previa
            </b-button>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTimeHumane }}
          </template>

          <template #cell(icon)="data">
            <img
              :src="data.item.icon"
              width="100"
              height="100"
              @error="handleImageError($event)"
              alt="Icon"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'notification-edit',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteNotification(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BLink,
  BPagination,
} from "bootstrap-vue";

import { formatDateTimeHumane } from "@core/utils/filter.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Push from "push.js";

export default {
  components: {
    BAvatar,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BPagination,
  },
  filters: {
    formatDateTimeHumane,
  },
  data() {
    return {
      loading: false,
      imgError: process.env.VUE_APP_AWS_BUCKET + "/icons/PNG/Imagen_no_disponible.png",
      items: {},
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    fields() {
      return [
        { key: "body", label: "Texto", sortable: true },
        { key: "icon", label: "Icono", sortable: true },
        { key: "button", label: "Vista Previa", sortable: true },
        { key: "created_at", label: "Creado", sortable: true },
        { key: "actions", label: "Acciones" },
      ];
    },
  },
  methods: {
    handleImageError(event) {
      event.target.src = this.imgError;
    },
    notificationPreview(notification) {
      Push.create("Andino App", notification);
    },
    async getItems(page = 1) {
      try {
        this.loading = true;
        this.items = await this.$store.dispatch("notifications/index", {
          page: page,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteNotification(id) {
      try {
        const res = await this.$bvModal.msgBoxConfirm(
          `Estas seguro de borrar esta notificación?`,
          {
            title: "Por favor confirmar",
            size: "sm",
            okVariant: "primary",
            okTitle: "Si",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (res) {
          await this.$store.dispatch("notifications/delete", id);
          this.getItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Éxito",
              icon: "TashIcon",
              variant: "success",
              text: "Notificación eliminado correctamente",
            },
          });
        } else {
          console.log("Cancelado");
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "No se pudo eliminar la Notificación",
          },
        });
      }
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
