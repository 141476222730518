var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"auth-inner m-0 d-flex align-items-center justify-content-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-link',[_c('b-img-lazy',{staticClass:"mt-1",attrs:{"src":_vm.appLogoImage,"alt":"logo","width":"190"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center pt-3 relative",attrs:{"md":"8"}},[_c('b-card',{staticClass:"w-100"},[_c('b-card-header',{staticClass:"d-flex justify-content-between align-items-center pt-75 pb-25"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Registro de usuario")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(" Iniciar sesión ")])],1)],1),_c('b-overlay',{staticClass:"table-responsive",attrs:{"show":_vm.loadingRegister,"rounded":"","responsive":"","variant":"transparent","opacity":"0.33","blur":"2px"}},[_c('form-wizard',{ref:"formWizard",staticClass:"w-100 primary-color",attrs:{"title":null,"subtitle":null,"finish-button-text":"Guardar","back-button-text":"Atras","next-button-text":"Siguiente","color":"#121441"},on:{"on-complete":_vm.registerMethod}},[_c('tab-content',{attrs:{"title":"Colegio","before-change":_vm.colegiosRules}},[_c('validation-observer',{ref:"colegiosRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"d-flex align-items-center justify-content-center"},_vm._l((_vm.schoolsOptions),function(tr,indexTr){return _c('b-col',{key:indexTr,attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center hoverEfect",on:{"click":function($event){return _vm.selectSchool(tr.id)}}},[_c('b-avatar',{class:[
                          ("" + (tr.id == _vm.formData.school_id ? 'imgLogoSelect' : '')) ],attrs:{"src":tr.logo,"alt":"logos","size":"90px","rounded":""}}),_c('h3',[_vm._v(_vm._s(tr.name))])],1)])}),1)],1)],1),_c('tab-content',{attrs:{"title":"Información Personal","before-change":_vm.informationRules}},[_c('validation-observer',{ref:"informationRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Informacion personal")])]),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nombres","label-for":"nombres"}},[_c('validation-provider',{attrs:{"name":"nombres","rules":"required|alpha-spaces|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombres","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required|alpha-spaces|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tipo de usuario","label-for":"typeUser"}},[_c('validation-provider',{attrs:{"name":"tipo de usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"typeUser","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","reduce":function (typeUser) { return typeUser.value; },"options":_vm.typeUserOptions,"state":errors.length > 0 ? false : null,"clearable":false,"searchable":false},model:{value:(_vm.formData.typeUser),callback:function ($$v) {_vm.$set(_vm.formData, "typeUser", $$v)},expression:"formData.typeUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Fecha de nacimiento","label-for":"birthday"}},[_c('validation-provider',{attrs:{"name":"fecha de nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('date-picker',{attrs:{"type":"date","default-value":new Date(),"disabled-date":_vm.disabledBeforeFourYears},model:{value:(_vm.formData.birthday),callback:function ($$v) {_vm.$set(_vm.formData, "birthday", $$v)},expression:"formData.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formData.typeUser == 'student')?_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Grado","label-for":"grado"}},[_c('validation-provider',{attrs:{"name":"grado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"grado","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.grado),callback:function ($$v) {_vm.$set(_vm.formData, "grado", $$v)},expression:"formData.grado"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2679829557)})],1)],1):_vm._e(),(_vm.formData.typeUser == 'student')?_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Sección","label-for":"section"}},[_c('validation-provider',{attrs:{"name":"section","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"section","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.section),callback:function ($$v) {_vm.$set(_vm.formData, "section", $$v)},expression:"formData.section"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3206329461)})],1)],1):_vm._e(),(_vm.formData.typeUser == 'student')?_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Aula","label-for":"aula"}},[_c('validation-provider',{attrs:{"name":"aula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"aula","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.aula),callback:function ($$v) {_vm.$set(_vm.formData, "aula", $$v)},expression:"formData.aula"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2918872405)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"placeholder":"+X 999 999 999"},on:{"validate":_vm.validateNumber},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Usuario","before-change":_vm.usuarioRules}},[_c('validation-observer',{ref:"usuarioRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Informacion Para iniciar sesión")])]),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Correo electronico","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contraseña","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',[_vm._v("Contraseña")]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contraseña","vid":"c_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',[_vm._v("Confirmar contraseña")]),_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-c_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-c_password"},model:{value:(_vm.formData.c_password),callback:function ($$v) {_vm.$set(_vm.formData, "c_password", $$v)},expression:"formData.c_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }